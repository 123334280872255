import './App.css';
import Nav from './components/Nav';
import Home from './components/Home';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Skills from './components/Skills';
import Services from './components/Services';
import Contact from './components/Contact';
import Login from './components/Login';
import Signup from './components/Signup';
import Alert from './components/Alert';
import Cv from './components/Cv';
// import Preloader from './components/Preloader';
import Footer from './components/Footer';
import Chat from './components/Chat';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes, Navigate
} from "react-router-dom";
import NoPage from './components/NoPage';



function App() {
  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type
    })
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  }

  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   // Listen for when all resources are loaded
  //   const handleLoad = () => {
  //     setLoading(false);
  //   };

  //   // Attach the onload event listener
  //   window.addEventListener('load', handleLoad);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener('load', handleLoad);
  //   };
  // }, []);

  const isLoggedIn = () => {
    // Check if user is authenticated
    return !!localStorage.getItem('token'); // Assuming you store the token in localStorage
  };


  return (
    <>
      {/* {loading ? <Preloader /> : */}
        <Router>
          <Alert alert={alert} />
          <Nav />
          <Routes>
            <Route exact path="/" element={<Home showAlert={showAlert} />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/skills" element={<Skills />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/login" element={<Login showAlert={showAlert} />} />
            <Route exact path="/signup" element={<Signup showAlert={showAlert} />} />
            <Route exact path="/cv" element={<Cv showAlert={showAlert} />} />
            {/* <Route exact path="/chat" element={<Chat showAlert={showAlert} />} /> */}
            <Route path="/chat" element={isLoggedIn() ? <Chat/> : <Navigate to="/login" />} />
            <Route path="*" element={<NoPage/>} />
          </Routes>
          <Footer />
        </Router>
      {/* } */}
    </>
  );
}

export default App;
