// src/components/ChatRoom.js

import React, { useState, useEffect } from 'react';
import UserList from './UserList';
import ChatWindow from './ChatWindow';

const Chat = () => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    if (selectedUser) {
      fetch(`http://127.0.0.1:8000/api/messages/${selectedUser.id}/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then(response => response.json())
        .then(data => setMessages(data))
        .catch(error => console.error('Error fetching messages:', error));
    }
  }, [selectedUser]);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    // Fetch messages for the selected user
    fetch(`http://127.0.0.1:8000/api/messages/${user.id}/`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }
    })
      .then(response => response.json())
      .then(data => setMessages(data));
  };

  const handleSendMessage = () => {
    fetch(`/api/messages/${selectedUser.id}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ text: newMessage }),
    })
      .then(response => response.json())
      .then(data => {
        setMessages(prevMessages => [...prevMessages, data]);
        setNewMessage('');
      })
      .catch(error => console.error('Error sending message:', error));
  };


  return (
    <>
      <div className="chat-app">
        <UserList users={onlineUsers} onSelect={handleUserSelect} />
        {selectedUser && (
          <ChatWindow user={selectedUser} messages={messages} />
        )}
      </div>

      <div>
        <div className="messages-list">
          {messages.map((msg, index) => (
            <div key={index}>
              <strong>{msg.sender}</strong>: {msg.text}
            </div>
          ))}
        </div>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>


    </>
  );
};



export default Chat;
