import React from 'react';
import logo from '../logo.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Nav = () => {
    const location = useLocation();
    let navigate = useNavigate();

    const navStyle = (location.pathname === '/login' || location.pathname === '/signup') ? {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    } : {};

    const handleLogout=()=>{
        localStorage.removeItem('token');
        navigate("/login");
    }
    

    return (
        <>
            <div className="header" style={navStyle}>
                <header className="container">
                    <nav className="navbar navbar-expand-lg navbar-light" style={{ zIndex: "2" }}>
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/">
                                <img className="logo" src={logo} alt="Logo" height="50" />
                            </Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="offcanvas">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="offcanvas offcanvas-start"  tabIndex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
                                <div className="offcanvas-header">
                                    <Link className="navbar-brand" to="/">
                                        <img className="logo" src={logo} alt="Logo" height="50" />
                                    </Link>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body">
                                    <ul className="navbar-nav ms-auto">
                                        <li className="nav-item">
                                            <Link className={`nav-link fs-5 ${location.pathname==="/"?"active":""}`} to="/">Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link fs-5 ${location.pathname==="/about"?"active":""}`} to="/about">About</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link fs-5 ${location.pathname==="/portfolio"?"active":""}`} to="/portfolio">Portfolio</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link fs-5 ${location.pathname==="/skills"?"active":""}`} to="/skills">Skills</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link fs-5 ${location.pathname==="/services"?"active":""}`} to="/services">Services</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link fs-5 ${location.pathname==="/contact"?"active":""}`} to='/contact'>Contact</Link>
                                        </li>
                                        {!localStorage.getItem('token')?
                                        <li className="nav-item">
                                            <Link to="/cv" className={`nav-link fs-5 ${location.pathname==="/login"?"active":""}`}>Download CV</Link>
                                        </li>:<button className='btn' onClick={handleLogout}>Logout</button> }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>

        </>
    )
}

export default Nav
