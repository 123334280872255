import React from 'react'

const NoPage = () => {
  return (
    <div className='mb-5'>
      <h1 className='text-center'>Page not found</h1>
    </div>
  )
}

export default NoPage
