import React, { useState } from 'react';
import contactimg from '../images/contact.webp'
import axios from 'axios';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://127.0.0.1:8000/api/contact/', formData);
            // console.log(response.data);
            alert('Message sent successfully!');
        } catch (error) {
            console.error(error.response.data);
            alert('Failed to send the message.');
        }
    };
    return (
        <>
            <section className="contact" id="contact">
                <div className="container-fluid">
                    <div className="container" >
                        <div className="row" >
                            <div className="col-md-12 text-center"><h4 className="orange">Contact Me</h4></div>
                            <div className="col-md-12 text-center mb-5"><h1>I Want To Hear From You</h1></div>
                        </div>
                        <div className="row" >
                            <div className="col-md-6">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6 col-6">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="name" name="name" autoComplete="off" value={formData.name} onChange={handleChange} required />
                                        </div>
                                        <div className="col-md-6 col-6">
                                            <label htmlFor="Contactemail" className="form-label">Email</label>
                                            <input type="email" className="form-control" id="Contactemail" name="email" autoComplete="off" value={formData.email} onChange={handleChange} required />
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="message" className="form-label">Message</label>
                                            <textarea className="form-control" id="message" name="message" value={formData.message} rows="5" onChange={handleChange} required></textarea>
                                        </div>
                                        <div className="col-12 mt-md-2 mt-2">
                                            <button type="submit" className="btn w-100 fw-bold mb-2" name="send" style={{ backgroundColor: "#ff6600" }}>Send</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <img className="contact" src={contactimg} alt="contact-us" width="100%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact
